<template>
  <div>
    首页
    <!-- <el-button @click="toCouponManage">跳转到优惠券管理</el-button> -->
  </div>
</template>

<script>
export default {
  methods: {
    toCouponManage(){
      console.log('couponManage')
      this.$router.push({path: '/index/couponManage'})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
